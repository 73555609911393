
$(document).ready(function() {

	$("#nav-icon").click(function() {
		$(this).toggleClass("open");
		$('body').toggleClass("noscroll");
		$(".right-header nav").slideToggle();
	  });

	  $('#datepicker').datepicker({
		format: 'dd-mm-yyyy',
		startDate: '+1d'
	});



	  $( "#time" ).selectmenu();
  
	  $('.menu li').on('click', function(){
		  $(this).addClass('active').siblings().removeClass('active');
	  });


	  /*$(".menu li a, .right-header .primary-button").on('click', function(event) {

		event.preventDefault();
	
		
		var hash = this.hash;
	
		$('html, body').animate({
		  scrollTop: $(hash).offset().top - 130
		}, 800, function(){
	   
		  window.location.hash = hash;
		});
	  });
	  */
	
});
